import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/katar-u-dzieci/" className="sel">
              {" "}
              &gt; Katar u dzieci
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/dzieci/zatkany_nos_u_dziecka/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/GettyImages-135538267.jpg')"}} />
                     
                    </div>
                    <div className="c2">Zatkany nos u dziecka </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/shutterstock_1668728821.jpg')"}} />
                     
                    </div>
                    <div className="c2">
                      Odporność dziecka - zadbaj i zwiększaj{" "}
                    </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.jpg" />
              </a>
              <a href="/katar-u-dzieci/" className="quiz_btn" />
            </div>
            <div className="col2">
              <div className="wysiwyg">
                <div>
                  <img
                    alt
                    src="/web/kcfinder/uploads/images/headingdiwci.png"
                    style={{
                      width: "317px",
                      height: "auto66"
                    }}
                  />
                </div>
                <div>&nbsp;</div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Kiedy u dziecka pojawia się katar należy otoczyć je szczególną
                  uwagą i zapewnić odpowiednią pielęgnację. Dziecięcy nosek jest
                  bardziej wrażliwy i inaczej zbudowany niż u dorosłego. Kanały
                  nosowe są wąskie i nawet mały obrzęk{" "}
                  <a href="/slowniczek/#blona_sluzowa_nosa" target="_blank">
                    śluzówki nosa
                  </a>{" "}
                  powoduje kłopoty w oddychaniu. Odmienna budowa{" "}
                  <a href="/slowniczek/#trabka_sluchowa" target="_blank">
                    Trąbki Eustachiusza
                  </a>
                  &nbsp;sprzyja rozprzestrzenianiu się infekcji (jest krótsza,
                  ma poziomy przebieg i jest cały czas otwarta) - ponad 60%
                  infekcji górnych dróg oddechowych prowadzi do ostrego
                  zapalenia ucha środkowego<sup>1</sup>.&nbsp;
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Zatkany nos, kłopoty z oddychaniem i zalegająca{" "}
                  <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                    wydzielina
                  </a>{" "}
                  powodują, że dziecko jest zmęczone i drażliwe, a w nocy
                  pojawiają się problemy ze snem.&nbsp;
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Bardzo ważne jest stosowanie leków, które szybko odblokują nos
                  oraz zapewnią swobodne oddychanie i odpływ wydzieliny. W ten
                  sposób zmniejszymy ryzyko pojawienia się groźnych powikłań
                  takich jak np. zapalenie ucha środkowego,{" "}
                  <a href="/slowniczek/#zatoki_przynosowe" target="_blank">
                    zapalenie zatok
                  </a>
                  , zapalenie oskrzeli.&nbsp;
                </div>
                <div>&nbsp;</div>
                <div>
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <sup>1</sup>Stockmann C, Ampofo K, Hersh AL, Carleton ST,
                    Korgenski K, Sheng X, Pavia AT, Byington CL. Seasonality of
                    acute otitis media and the role of respiratory viral
                    activity in children Pediatr Infect Dis J. 2013
                    Apr;32(4):314-9. doi: 10.1097/INF.0b013e31827d104e.PubMed
                    PMID: 23249910; PubMed Central PMCID: PM3618601&nbsp;
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="drugs">
              <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/packshot_bez_tla.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY HA DLA DZIECI
                    </p>
                    <div className="desc">
                      Szybko i delikatnie odblokowuje i nawilża małe noski.
                    </div>
                    <a
                      href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                      className="more"
                    >
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/Sudafed_DEX_Kids_kartonik%2Bbutelka%20.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY DEX DLA DZIECI
                    </p>
                    <div className="desc">
                    Szybko i delikatnie odblokowuje i pomaga w gojeniu ran w małych noskach
                    </div>
                    <a
                      href="/produkty/sudafed_xylospray_dex_kids/"
                      className="more"
                    >
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/dla_dzieci.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY DLA DZIECI
                    </p>
                    <div className="desc">
                      Szybko i delikatnie odblokowuje nos i zatoki. Umożliwia
                      spokojny sen bez kataru aż do 10 godzin
                    </div>
                    <a
                      href="/produkty/sudafed_xylospray_dla_dzieci/"
                      className="more"
                    >
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/actifed.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      <span
                        style={{
                          color: "#cb0073"
                        }}
                      >
                        ACTIFED<sup>®</sup> SYROP
                        <br /> NA KATAR I KASZEL
                      </span>
                    </p>
                    <div className="desc">
                      Odblokowuje nos, zmniejsza ilość wydzieliny oraz łagodzi
                      suchy kaszel.
                    </div>
                    <a
                      href="/produkty/actifed_syrop_na_katar_i_kaszel/"
                      className="more"
                    >
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                
              </div>
              <div className="clear note"></div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
